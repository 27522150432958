import React from 'react';

const Widgets = () => {
  return (
    <div className="flex mb-2 gap-4">
      <div className="bg-white shadow-md p-4 pt-4 h-24 w-auto rounded-[24px] flex gap-4">
        <div className='h-16 flex items-center justify-center'>
          <div className="w-14 h-14 rounded-full bg-gray-300 flex items-center justify-center">
          </div>
        </div>
        <div className='space-y-[-10px]'>
          <h3 className="w-[200px] h-[36px] font-roboto font-normal text-[#697077]">Register patient</h3>
          <div className='flex text-slate-700'>
          <div className="text-2xl font-bold ">2.352M</div>
          <div className=" ml-12 mt-1">+11%</div>
          </div>
        </div>
        
      </div>

      <div className="bg-white shadow-md p-4 pt-4  h-24 w-auto rounded-[24px] flex gap-4">
        <div className='h-16 flex items-center justify-center'>
          <div className="w-14 h-14 rounded-full bg-gray-300 flex items-center justify-center">
          </div>
        </div>
        <div className='space-y-[-10px]'>
          <h3 className="w-[200px] h-[36px] font-roboto font-normal text-[#697077]">Today Payment</h3>
          <div className='flex text-slate-700'>
          <div className="text-2xl font-bold">2.352M</div>
          <div className=" ml-8 mt-1">+11%</div>
        </div>
        </div>
        
      </div>

      <div className="bg-white shadow-md p-4 pt-4  h-24 w-auto rounded-[24px] flex gap-4">
        <div className='h-16 flex items-center justify-center'>
          <div className="w-14 h-14 rounded-full bg-gray-300 flex items-center justify-center">
          </div>
        </div>
        <div className='space-y-[-10px]'>
          <h3 className="w-[200px] h-[36px] font-roboto font-normal text-[#697077]">Worker</h3>
          <div className='flex text-slate-700'>
          <div className="text-2xl font-bold">150</div>
        </div>
        </div>
        
      </div>


      <div className="bg-white justify-center flex flex-col shadow-md p-4 pt-2 h-24 rounded-[24px]">
        <span className="text-[14px] whitespace-nowrap font-roboto font-normal text-[#697077]">MON</span>
        <p className='flex items-end gap-3'><span className="text-slate-600 text-[26px] mb-[-4px]">12</span> <span>doctors working in today</span></p>
      </div>
    </div>
  );
};

export default Widgets;
