import React, { useState } from 'react';

const appointments = [
    {
        test: "Upper Abdomen General – Test Code 2705",
        time: "10:13 - 12:00",
    },
    {
        test: "Gynecologic Disorders",
        time: "10:13 - 12:00",
    },
    {
        test: "Upper Abdomen General – Test Code 2705",
        time: "10:13 - 12:00",
    },
    {
        test: "Upper Abdomen General – Test Code 2705",
        time: "10:13 - 12:00",
    },
    {
        test: "Upper Abdomen General – Test Code 2705",
        time: '10:13 - 12:00',
    }
];

const AllAppointments = () => {
    const [isAppointmentVisible, setIsAppointmentVisible] = useState(false);

    const handleButtonClick = () => {
        setIsAppointmentVisible(true);
    };
    
    const closeForm = () => {
        setIsAppointmentVisible(false);
    };

    return (
        <div className="mt-8   sm:mr-[70%] lg:mr-[40%]">
            <div className=" sm:rounded-lg">
                <table className="w-[126%] divide-y divide-gray-100 ">
                    <thead className="">
                        <tr>
                            <th className="px-1 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Time</th>
                            <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Test</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {appointments.map((appointment, index) => (
                            <tr key={index}>
                                <td className="px-1 py-4 whitespace-nowrap text-sm text-black">{appointment.time}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.test}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllAppointments;
