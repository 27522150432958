import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/user'; // Ensure the path is correct

const store = configureStore({
  reducer: {
    user: userReducer, // Add other reducers here as needed
  },
  // You can add middleware, devTools, and other configurations here if needed
});

export default store;
