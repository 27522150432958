import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { server } from '../../server';
import { fetchUser } from '../../Redux/actions/user';
import axios from 'axios';
import PlusButton from '../reception/patient/PlusButton';

const fetchEmployee = async (clinicId) => {
  try {
    const response = await axios.get(`${server}/api/v2/employee/byclinic/${clinicId}`);
    console.log('API Response:', response.data); // Log to ensure correct data
    return response.data.data; // Return only the array of employees
  } catch (error) {
    console.log('Error fetching employees', error);
    throw error;
  }
};

const SearchEmployee = ({ onPlusClick, onEmployeeClick }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    const loadEmployees = async () => {
      if (user && user.clinicId && user.clinicId.length > 0) {
        const clinicId = user.clinicId[0]?.clinic;

        if (clinicId) {
          try {
            const data = await fetchEmployee(clinicId);
            console.log('Fetched Employees:', data); // Ensure data is correct
            setEmployees(data);
          } catch (error) {
            setError('Failed to fetch employees');
            console.error('Fetch Error:', error);
          }
        } else {
          setError('Clinic ID is not valid');
        }
      } else {
        setError('No valid clinic ID found');
      }
    };

    if (user && user.clinicId && user.clinicId.length > 0) {
      loadEmployees();
    }
  }, [user]);

  return (
    <div className="p-4 mt-[-30px]">
 <div className="w-full mb-2">
      <div className="flex justify-between w-full leading-[40px] mt-12">
        <h2 className="ml-2 font-bold">Employee</h2>
      
        <PlusButton onClick={onPlusClick} />
    </div>
    </div>
    <input
  type="text"
  placeholder="Search Employee"
  className="w-full py-2 mb-2 rounded-full px-4 text-sm border border-gray-300 sm:py-1 sm:px-2 md:py-2 mb-[md:px-4"
/>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="space-y-2 w-[210px]">
        {employees.length > 0 ? (
          employees.map((employee) => {
            const fullName = [employee.firstName, employee.middleName, employee.lastName].filter(Boolean).join(' ');
            return (
              <div
                key={employee._id} // Ensure `_id` is unique
               className="flex items-center   bg-gray-200 p-3 rounded-full cursor-pointer"
                onClick={() => onEmployeeClick(employee)}
              >
                <span className="bg-gray-300 text-center text-gray-800 w-10 h-10 leading-10 rounded-full">
                
                  {employee.firstName ? employee.firstName[0] : 'N/A'}
                </span>
                <span className="ml-4 max-w-[70%] overflow-hidden whitespace-nowrap text-ellipsis text-gray-700 text-sm">{fullName}</span>
              </div>
            );
          })
        ) : (
          <div className="text-gray-500">No employees found</div>
        )}
      </div>
    </div>
  );
};

export default SearchEmployee;
