import React from 'react';
import { FaSearch, FaFilter } from 'react-icons/fa';

const SearchBarWithFilter = ({
  value,
  onChange
}) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-center border rounded-md overflow-hidden bg-white">
        <div className="flex items-center px-4">
          <FaSearch className="text-[#666E7D80]" />
        </div>
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type="text"
          placeholder=""
          className="px-2 py-2 w-full text-gray-700  focus:outline-none"
        />
        <div className="flex items-center px-4 border-l">
          <FaFilter className="text-gray-400" />
        </div>
      </div>
      <button className="px-4 py-2 bg-[#F9F9FB] text-black rounded-md">
        Search
      </button>
    </div>
  );
};

export default SearchBarWithFilter;
