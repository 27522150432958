import React from 'react';
import BarChart from './smallComponents/barChart';
import PieChart from './smallComponents/pieChart';
import AllAppointmentList from './allPatientAppointment';
const Activity = () => {
  return (
    <div className="flex p-6">
      <div className='flex flex-col gap-4 w-[25%]'>
        <PieChart/>
        <BarChart/>
      
      </div>

      <AllAppointmentList onlyTable={true} length={15} profilePics={true} />

        
    </div>
  );
};

export default Activity;
