import React, { useState } from 'react';
import AllAppointmentList from '../../components/clinic/allPatientAppointment';
import TopBar from '../../components/clinic/TopBar';
import Sidebar from '../../components/clinic/sideBar';
import SearchBarWithFilter from '../../components/clinic/searchWithFilter';
const AllAppointments = () => {
  
  const [searchInput, setSearchInput] = useState('');

  return (
    <div className="relative min-h-screen overflow-x-hidden ">
      <div className="p-2">
        <SearchBarWithFilter value={searchInput} onChange={setSearchInput} />
      </div>
      <AllAppointmentList filter={searchInput} />
    </div>
  );
};

export default AllAppointments;
