import React from 'react';
import { FaSearch, FaChevronDown } from 'react-icons/fa';

const SearchBar = () => {
  return (
    <div className="flex items-center space-x-2">
      <div className="flex border rounded-md overflow-hidden">
        <button className="flex items-center w-[130px] px-[10px] py-2 text-left  text-gray-700">
          All
          <FaChevronDown className=" ml-[80px] text-gray-400 w-[10px] h-4" />
        </button>
        <div className="flex items-center border-l px-4">
          <FaSearch className="text-gray-300" />
          <input
            type="text"
            placeholder="Search"
            className="px-4 py-2  text-sm text-gray-700 focus:outline-none"
          />
        </div>
      </div>
      <button className="px-4 py-2  bg-[#F9F9FB] border text-black rounded-md">
        Search
      </button>
    </div>
  );
};

export default SearchBar;
