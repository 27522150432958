import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { server } from '../../../server';

const NotesSection = ({ isSearchVisible, patient }) => {

  
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.user);
  const id = user?.clinicId[0]?.clinic || '';

  useEffect(() => {

    const fetchAppointments = async () => {
      if (!id) {
        console.log('Clinic ID not available yet');
        return; // Return early if no clinic ID is available
      }

      try {
        const response = await axios.get(`${server}/api/v2/medical-history/by-patient`, {
          data: patient
        });
        console.log(response.data);
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching appointments:", error);
        setLoading(false); // Set loading to false if there's an error
      }
    };

    fetchAppointments();
  }, [id]);

  return (
    <div
      className={`w-[40%] bg-[#FAFAFA] border border-[#00B5CE] rounded-[15px] p-2 transition-all duration-300 ease-in-out`}
    >
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-gray-400 font-semibold">Notes</h3>
        <button className="text-[#00B5CE] font-semibold">See all</button>
      </div>
      <div className="bg-white">
        <ul className="space-y-2">
          <li className="text-gray-800">• Diagnosed with flu</li>
          <li className="text-gray-800">• Perscribed with some pain killers</li>
        </ul>
        <div className="my-4 flex items-center justify-between text-gray-400">
          <span className="flex-grow border-t"></span>
          <span className="mx-4 text-[10px] text-black">Mon 14 / 2000</span>
          <span className="flex-grow border-t"></span>
        </div>
        <ul>
          <li className="mt-[-16px] text-gray-800">• Perscribed with pain killers</li>
        </ul>
        <div className='flex justify-end'>
          <button className="bg-cyan-500 text-white px-4 py-1 rounded-md">
            <p>Edit</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotesSection;
