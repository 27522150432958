import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { server } from "../../server";
import axios from 'axios';
import { fetchUser } from './../../Redux/actions/user';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { user, loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  // const [user, setUser] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchUserProfile = async () => {
  //     setLoading(true);
  //     setError(null);

  //     try {
  //       const token = localStorage.getItem('token');
  //       if (token) {
  //         const decodedToken = jwtDecode(token);
  //         const userId = decodedToken.id;
  //         const response = await axios.get(`${server}/api/v2/employee/${userId}`);
  //         // Access the user data from the `data` field
  //         setUser(response.data.data);
  //         console.log(response);
  //       } else {
  //         setError('No token found');
  //       }
  //     } catch (err) {
  //       setError(err.response?.data?.message || 'Error fetching user data');
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchUserProfile();
  // }, []);

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  return (
    <div className="flex items-center space-x-4 p-4">

      <div className="flex-shrink-0">
        <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center">
          <span className="text-lg font-medium text-gray-700">
            {user?.firstName?.charAt(0).toUpperCase() || ''}
          </span>
        </div>
      </div>

      <div>
        <div className="text-base text-md font-semibold w-full text-gray-900">
          {`${user?.firstName || ''} ${user?.middleName || ''} ${user?.lastName || ''}`.trim() || 'Unknown User'}
        </div>
        <div className="text-sm text-gray-500">{`${user?.email}`}</div>
      </div>
    </div>
  );
};

export default UserProfile;
