import React from 'react';

const Widgets = () => {
  return (
    <div className="flex mb-8 gap-8">
      <div className="bg-white shadow-md p-4 pt-4 h-24 w-auto rounded-[24px] flex gap-4">
        <div className='h-16 flex items-center justify-center'>
          <div className="w-14 h-14 rounded-full bg-gray-300 flex items-center justify-center">
          </div>
        </div>
        <div className='space-y-[-10px]'>
          <h3 className="w-[200px] h-[36px] font-roboto font-normal text-[#697077]">Register patient</h3>
          <div className='flex text-slate-700'>
          <div className="text-2xl font-bold ">2.352M</div>
          <div className=" ml-12 mt-1">+11%</div>
          </div>
        </div>
        
      </div>

      <div className="bg-white shadow-md p-4 pt-4  h-24 w-auto rounded-[24px] flex gap-4">
        <div className='h-16 flex items-center justify-center'>
          <div className="w-14 h-14 rounded-full bg-gray-300 flex items-center justify-center">
          </div>
        </div>
        <div className='space-y-[-10px]'>
          <h3 className="w-[200px] h-[36px] font-roboto font-normal text-[#697077]">Today Payment</h3>
          <div className='flex text-slate-700'>
          <div className="text-2xl font-bold">2.352M</div>
          <div className=" ml-8 mt-1">+11%</div>
        </div>
        </div>
        
      </div>

      
      <div className="bg-white shadow-md px-4 pt-4 h-24 w-[250px]  rounded-[24px] gap-2 ">
        <h3 className="text-[10px] text-slate-400">MON</h3>
        <div className="text-3xl text-slate-700 font-bold">08:54:58</div>
        <div className="text-[10px] text-slate-400">Let get moving</div>
      </div>
    </div>
  );
};

export default Widgets;
