import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FilterOptions from './filter';
import axios from 'axios';
import { server } from '../../server';
import SmallLoading from '../smallLoading'; // Import your loading animation component
import { matchAll } from '../../utils/matchAll';
import { useNavigate } from 'react-router-dom';


const AllAppointmentList = ({
  filter = '',
  length = 0,
  onlyTable = false,
  profilePics = false
}) => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const user = useSelector((state) => state.user.user);
  const id = user?.clinicId[0]?.clinic || '';
  const navigate = useNavigate();

  const [isAppointmentVisible, setIsAppointmentVisible] = useState(false);

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!id) {
        console.log('Clinic ID not available yet');
        return; // Return early if no clinic ID is available
      }

      try {
        const response = await axios.get(`${server}/api/v2/appointments/clinic/${id}`);
        let sortedAppointments = response.data.data.sort((a, b) =>
          new Date(b.appointmentDate) - new Date(a.appointmentDate)
        );
        if(length) sortedAppointments = sortedAppointments.slice(0, length);
        setAppointments(sortedAppointments);
        setLoading(false);
        console.log(sortedAppointments);
      } catch (error) {
        console.error("Error fetching appointments:", error);
        setLoading(false); // Set loading to false if there's an error
      }
    };

    fetchAppointments();
  }, [id]);

  const handleRowClick = (appointment) => {
    // console.log('Row clicked:', appointment);
    navigate(user.role == 'Reception' ? '/addPatient' : '/patientDetail', {
      state: {
        patientId: appointment.patientId
      }
    });
  };

  return (
    <div className="w-full p-4 overflow-x-hidden">
      {/* Heading and Filter Options */}
      {!onlyTable && <p className='text-gray-400 text-center'>To add appointments head to a patient detail</p>}
              
      {!onlyTable && <div className='flex justify-between'>
 
        <p className='text-gray-400'>
          Showing <span className='text-black'>{appointments.length} appointments</span>
   
        </p>
        <FilterOptions />
      </div>}
 
   
      <div className="bg-white shadow sm:rounded-lg overflow-y-scroll ">
         {loading ? (
          <div className="flex justify-center items-center h-64"> {/* You can adjust height as needed */}
            <SmallLoading /> {/* Loading animation */}
          </div>
        ) : (
        <table className="w-full divide-y divide-gray-100">
          <thead className="">
            <tr>
              {profilePics && <th></th>}
              <th className="px-6 py-3 bg-gray-200 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 bg-gray-200 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Time</th>
              <th className="px-6 py-3 bg-gray-200 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Patient</th>
              <th className="px-6 py-3 bg-gray-200 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Doctor</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {appointments.filter(({ patientId }) => {
              if(!filter) return true;
              return matchAll(
                  filter,
                  patientId.firstName,
                  patientId.middleName,
                  patientId.lastName,
                  [patientId.firstName,
                    patientId.middleName,
                    patientId.lastName].join(' ')
                );
            }).map((appointment, index) => (
              <tr key={index}
              onClick={() => handleRowClick(appointment)}
              className='cursor-pointer hover:bg-gray-300'
              >
                {profilePics && <td className='w-12 px-2'>
                  <div className="bg-gray-300 text-center leading-10 text-gray-800 w-10 h-10 rounded-full">
                    {appointment.patientId?.firstName ? appointment.patientId?.firstName[0] : ''}
                  </div>
                </td>}
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{new Date(appointment.appointmentDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    })}</td>
                <td className=" px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.appointmentTime || 'N/A'}</td>
                <td className=" px-6 py-4 whitespace-nowrap text-sm text-black">
                    {appointment.patientId ? `${appointment.patientId.firstName} ${appointment.patientId.middleName} ${appointment.patientId.lastName}  ` : 'N/A'}

                  </td>
                <td className=" px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.scheduleID.doctorId.firstName}</td>
               </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );

};

export default AllAppointmentList;
