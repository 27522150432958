import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const DateSelector = () => {
  return (
    <div className="flex items-center whitespace-nowrap  rounded-md px-4 py-2  mb-2 ml-[610px]">
      <div className='border border-gray-300 w-6 h-6 pl-[6px] pt-[-12px] rounded-[50%] '>
      <button className="text-[#666E7D] text-[10px] hover:text-gray-700 ">
        <FaChevronLeft />
      </button>
      </div>
      <div className="mx-4 text-gray-800">
        <span className="text-sm font-semibold">Jun 24, 2022</span>
        <span className="text-[#585E6A] text-sm ml-2">Today</span>
      </div>
      <div className='border border-gray-300 w-6 h-6 pl-[6px] pt-[-12px] rounded-[50%] '>

      <button className="text-[#666E7D] text-[10px] hover:text-gray-700">
        <FaChevronRight />
      </button>
      </div>

    </div>
  );
};

export default DateSelector;
