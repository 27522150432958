import React from 'react';

const PlusButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center bg-cyan-300 justify-center w-[40px] h-[40px] rounded-full text-white text-2xl"
    >
       <p>+</p>
    </button>
  );
};

export default PlusButton;
