import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { user, loading } = useSelector((state) => state.user);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  if (!token) {
    return navigate('/');
    window.location.reload(true); // Redirect to login if no token
    
      
  }

  if (!user || !allowedRoles.includes(user.role)) {
   return navigate('/');
   window.location.reload(true);
    // window.location.reload(true);
      // Redirect to forbidden if the role is not allowed
  }

  return children; // Render the child components if everything is okay
};

export default ProtectedRoute;
