// employeeSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    data: {},
    submissionStatus: null,
  },
  reducers: {
    setEmployeeData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    submitEmployeeDataSuccess: (state, action) => {
      state.submissionStatus = 'success';
      state.data = action.payload;
    },
    submitEmployeeDataFailure: (state) => {
      state.submissionStatus = 'failure';
    },
    
  },
});

// Action creators generated by createSlice
export const {
  setEmployeeData,
  submitEmployeeDataSuccess,
  submitEmployeeDataFailure,
} = employeeSlice.actions;

// Custom action creator for saga

export const submitEmployeeDataRequest = (employeeData, callback) => ({
  type: 'employee/submitEmployeeDataRequest',
  payload: employeeData,
  callback, 
});
export const resetSubmissionStatus = () => ({
  type: 'employee/resetSubmissionStatus',
});



export default employeeSlice.reducer;
