import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SidebarReception from '../reception/sideBar';


const Sidebar = () => {
  const user = useSelector((state) => state.user.user);
  if(user?.role == 'Reception') return <SidebarReception />
  return (
    <div className="flex h-under-nav">
      <div className="w-48 py-32 bg-[#01B5CE] items-center">
        <NavLink
          to="/clinic"
          className={({ isActive }) =>
            isActive ? "block w-42 p-4 mb-2 bg-white text-[#00B5CE] pl-[35px]" : "block p-4 pl-[35px] mb-2  bg-[#04AAAF] text-white"
          }
        >
          <span className={({ isActive }) => 
            isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
          }>
            Dash board
          </span>
        </NavLink>
        <NavLink
          to="/allappointments"
          className={({ isActive }) =>
            isActive ? "block p-4 mb-2  bg-white text-[#00B5CE] pl-[35px]" : "block p-4  mb-2  pl-[35px] bg-[#04AAAF] text-white"
          }
        >
          <span className={({ isActive }) => 
            isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
          }>
            Appointments
          </span>
        </NavLink>
        <NavLink
          to="/patientDetail"
          className={({ isActive }) =>
            isActive ? "block p-4 mb-2  bg-white text-[#00B5CE] pl-[35px] " : "block p-4 mb-2 pl-[35px]  bg-[#04AAAF] text-white"
          }
        >
          <span className={({ isActive }) => 
            isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
          }>
            Patient
          </span>
        </NavLink>
        <NavLink
          to="/employee"
          className={({ isActive }) =>
            isActive ? "block p-4 mb-2  bg-white text-[#00B5CE] pl-[35px]" : "block p-4 pl-[35px]  mb-2  bg-[#04AAAF] text-white"
          }
        >
          <span className={({ isActive }) => 
            isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
          }>
            Employee
          </span>
        </NavLink>
        <NavLink
          to="/allService"
          className={({ isActive }) =>
            isActive ? "block p-4 mb-2  bg-white text-[#00B5CE] pl-[35px]" : "block p-4 pl-[35px] mb-2 bg-[#04AAAF] text-white"
          }
        >
          <span className={({ isActive }) => 
            isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
          }>
            Service
          </span>
        </NavLink>
        
        
      </div>
    </div>
  );
};

export default Sidebar;
