// components/Layout.js
import React from 'react';
import TopBar from '../superAdminDashBoard/topBar'; 
import Sidebar from '../clinic/sideBar';

const Layout = ({ children }) => {
  return (
    <div className="inset-0">
      <TopBar />
      <div className="flex">
        <Sidebar />
        <div className="overflow-auto w-full h-under-nav">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
