import React from 'react';
import TopBar from '../../components/reception/topBar';
import Sidebar from '../../components/reception/sideBar';
import Widgets from '../../components/reception/dashboardWidget';
import Activity from '../../components/reception/mainContent';
import AllAppointmentList from '../../components/clinic/allPatientAppointment';

const ReceptionDashboard = () => {
  return (
    <div className="flex min-h-screen bg-gray-100">
      <div className="flex-1 p-6">
        {/* <div className=''>
          <TopBar />
        </div> */}



        <Widgets />

        <div className="flex gap-2">
          <Activity />
          <AllAppointmentList onlyTable={true} length={5} />
        </div>
      </div>
    </div>
  );
};

export default ReceptionDashboard;
