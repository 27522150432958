import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Sidebar from '../../components/auth/signInSideBar';
import {
  setOwnerData,
  setAddressData,
  submitSignupRequest,
  resetSubmissionStatus,
} from '../../slices/signUp';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUpForm = () => {
  const [showNextPage, setShowNextPage] = useState(false); 
  const dispatch = useDispatch();
  const ownerData = useSelector((state) => state.signup.ownerData);
  const submissionStatus = useSelector((state) => state.signup.submissionStatus);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in ownerData.address) {
      dispatch(setAddressData({ [name]: value }));
    } else {
      dispatch(setOwnerData({ [name]: value }));
    }
  };

  const handleNextClick = () => {
    setShowNextPage(true); 
  };

  const handleBackClick = () => {
    setShowNextPage(false); 
  };

  const handleRegister = () => {
    dispatch(submitSignupRequest(ownerData));
  };

  return (
    <div className="relative flex h-screen">
      <ToastContainer />

      <div>
        <Sidebar />
      </div>

      <div className="flex flex-grow items-center ml-40">
        <div className="rounded-lg w-full max-w-md">
          {!showNextPage ? (
            <>
              <h1 className="text-2xl font-bold mb-4">Register</h1>
              <p className="mb-6 text-sm text-gray-400 font-bold">Owner Information</p>
              <div className="flex">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="border border-gray-300 p-2 px-6 mr-6 rounded-[15px]"
                  value={ownerData.firstName}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="middleName"
                  placeholder="Middle Name"
                  className="border border-gray-300 p-2 px-6 mr-6 rounded-[15px]"
                  value={ownerData.middleName}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="border border-gray-300 p-2 px-6 rounded-[15px]"
                  value={ownerData.lastName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex">
                <input
                  type="email"
                  name="email"
                  placeholder="Email ID"
                  className="border border-gray-300 text-left p-2 px-20 mt-4 rounded-[15px]"
                  value={ownerData.email}
                  onChange={handleInputChange}
                />
                <select
                  name="sex"
                  className="border border-gray-300 p-2 ml-32 px-24 mt-4 rounded-[15px]"
                  value={ownerData.sex}
                  onChange={handleInputChange}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div className="flex items-center mt-4">
                <div className="flex-shrink-0">
                  <select className="border border-gray-300 p-2 px-6 rounded-[15px]">
                    <option value="+251">+251</option>
                  </select>
                </div>
                <div>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="Phone"
                    className="border border-gray-300 p-2 px-[20px] w-[600px] rounded ml-2 rounded-[15px]"
                    value={ownerData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex ml-[680px]">
                <button
                  className="bg-cyan-500 text-black px-2 font-bold w-28 rounded mt-16 py-2 text-center text-sm"
                  onClick={handleNextClick}
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <div className="mt-10">
              <h1 className="text-2xl font-bold mb-4">Register</h1>
              <p className="mb-6 text-sm text-gray-400 font-bold">Address Information</p>
              
              <button 
                className="mb-4  ml-[-90px]" 
                onClick={handleBackClick}
              >
                <i className="  fas fa-arrow-left ml-[20px]"></i> 
              </button>

              <div className="mb-4">
                <input
                  type="text"
                  name="address_line_1"
                  placeholder="Address Line 1"
                  className="border border-gray-300 p-2 px-6 w-[600px] rounded-[15px]"
                  value={ownerData.address.address_line_1}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="address_line_2"
                  placeholder="Address Line 2"
                  className="border border-gray-300 p-2 px-6 w-[600px] rounded-[15px]"
                  value={ownerData.address.address_line_2}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  className="border border-gray-300 p-2 px-6 w-[600px] rounded-[15px]"
                  value={ownerData.address.city}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  className="border border-gray-300 p-2 px-6 w-[600px] rounded-[15px]"
                  value={ownerData.address.state}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="postal_code"
                  placeholder="Postal Code"
                  className="border border-gray-300 p-2 px-6 w-[600px] rounded-[15px]"
                  value={ownerData.address.postal_code}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  className="border border-gray-300 p-2 px-6 w-[600px] rounded-[15px]"
                  value={ownerData.address.country}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex mt-8">
                <button
                  className="bg-cyan-500 text-black font-bold px-[270px] rounded py-2 text-center text-sm"
                  onClick={handleRegister}
                >
                  Register
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
