import React from 'react';

const Activity = () => {
  return (
    <div className='w-[30rem]'>
      <div className="p-6 shadow-md bg-white rounded-2xl">
        <div className="mb-4">
          <h2 className="text-2xl font-bold text-center text-slate-700">Productive</h2>
          <p className='text-slate-400 text-center'>Something something something some content</p>
        </div>
        <div className="flex flex-col items-center justify-center gap-6">
          <div className="relative flex  items-center justify-center">
            <div className="flex items-center justify-center font-extrabold text-cyan-500 w-40 h-40 border-[20px] border-slate-500 border-t-cyan-400 border-l-cyan-400 rounded-[80px]">
              50%
            </div>
          </div>
          <div className="w-full flex gap-2 mb-10 ml-4">
            <div className='w-[50%] flex flex-col'>
              <span className="text-slate-400 text-[12px]">Something</span>
              <span className='text-3xl text-slate-800 font-extrabold'>890</span>
            </div>
            <div className='w-[50%] flex flex-col'>
              <span className="text-slate-400 text-[12px]">Something</span>
              <span className='text-3xl text-slate-800 font-extrabold'>890</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
