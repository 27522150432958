import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClinicsRequest, activateClinicRequest,deactivateClinicRequest } from '../../slices/fetchAllClinic'; // Adjust the path accordingly
import Sidebar from '../../components/superAdminDashBoard/sideBar';
import SearchClinics from '../../components/superAdminDashBoard/searchClinics';

const Management = () => {
  const dispatch = useDispatch();
  const { clinics, loading, error } = useSelector((state) => state.clinics);

  useEffect(() => {
    dispatch(fetchClinicsRequest());
  }, [dispatch]);

  const handleActivateClinic = (clinicId) => {
    console.log('Activating clinic:', clinicId);
    console.log('Action Type:', activateClinicRequest(clinicId).type);
    dispatch(activateClinicRequest(clinicId));
  };

  const handleDeActivateClinic = (clinicId) => {
    console.log('DeActivating clinic:', clinicId);
    console.log('Action Type:', deactivateClinicRequest(clinicId).type);
    dispatch(deactivateClinicRequest(clinicId));
  };
  

  return (
    <div className="flex h-screen">
      <SearchClinics />
      <div className="w-[1px] bg-gray-200"></div>
      <div className="flex-1 bg-white p-8 pt-20">
        <div className="mb-6">
          <div className="flex items-center mb-2">
            <div className="text-lg">Active User</div>
            <div className="font-bold underline cursor-pointer ml-4">Requests</div>
          </div>
          <input
            type="text"
            placeholder="Search Clinics"
            className="w-[500px] p-2 border border-gray-300 rounded-[50px]"
          />
        </div>
        <div className="ml-[-24px] text-gray-600 mb-4">
          Showing: <span className='font-bold'>{clinics.length} clinics</span>
        </div>
        <div className="ml-[-24px]">
          {loading ? (
            <div className="text-center py-6">Loading...</div>
          ) : error ? (
            <div className="text-center py-6 text-red-500">Error: {error}</div>
          ) : (
            <table className="bg-white border border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="text-left w-[160px] text-[12px] py-3 px-4 text-gray-400 uppercase">
                    Name of Clinic
                  </th>
                  <th className="text-left text-[12px] py-3 px-4 text-gray-400 uppercase">
                    Owner
                  </th>
                  <th className="text-left text-[12px] py-3 px-4 text-gray-400 uppercase">
                    Location
                  </th>
                  <th className="text-left text-[12px] py-3 px-4 text-gray-400 uppercase">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {clinics.map((clinic) => (
                  <tr key={clinic._id} className="border-t hover:bg-gray-50">
                    <td className="py-3 text-[14px] w-[80px] px-4">{clinic.clinicName}</td>
                    <td className="py-3 text-[14px] px-4">{clinic.adminId[0]}</td>
                    <td className="py-3 text-[14px] px-4">
                      {`${clinic.address[0].address_line_1}, ${clinic.address[0].city}, ${clinic.address[0].state}`}
                    </td>
                    <td className="py-3 text-[14px] px-4 flex space-x-2">
                      <button
                        className={`${
                          clinic.status === 'Activated'
                            ? 'bg-green-500 font-bold'
                            : 'bg-cyan-500'
                        } text-white w-16 px-2 py-2 rounded-lg`}
                        onClick={() => handleActivateClinic(clinic._id)}
                      >
                        <div className="text-[12px]">ACCEPT</div>
                      </button>
                      <button
                      className={`${
                        clinic.status === 'Activated'
                          ? 'bg-red-500 font-bold'
                          : 'bg-cyan-500'
                      } text-white w-16 px-2 py-2 rounded-lg`}
                      onClick={() => handleDeActivateClinic(clinic._id)}
                    >
                      
                        <div className="text-[12px]">REJECT</div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Management;
