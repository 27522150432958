import React from 'react';
import TopBar from '../../components/reception/topBar';
import Sidebar from '../../components/clinic/sideBar';
import Widgets from '../../components/superAdminDashBoard/dashboardWidget';
import Activity from '../../components/clinic/mainContent';

const ClinicAdminDashboard = () => {
  return (
    <div className="flex min-h-screen bg-gray-100">
      <div className="flex-1 p-6">

        <Widgets />
        

        <Activity />
      </div>
    </div>
  );
};

export default ClinicAdminDashboard;
