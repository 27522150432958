import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { server } from "../../server";
import axios from 'axios';

const EditEmployeeForm = ({ onClose, selectedEmployee }) => {
  const [employeeData, setEmployeeData] = useState({
    fullName: '',
    scheduleDate: '',
    scheduleTime: '',
    scheduleTimeEnd: '',
    scheduleType: '',
    doctorId: '',
    clinicId: '',
  });

  useEffect(() => {
    if (selectedEmployee) {
      console.log("Selected Employee Data:", selectedEmployee);
      setEmployeeData({
        fullName: selectedEmployee.fullName || '',
        scheduleDate: selectedEmployee.scheduleDate || '',
        scheduleTime: selectedEmployee.scheduleTime || '',
        scheduleTimeEnd: selectedEmployee.scheduleTimeEnd || '',
        scheduleType: selectedEmployee.scheduleType || '',
        doctorId: selectedEmployee.employeeId || '', // Populate employeeId
        clinicId: selectedEmployee.clinicId || '',
        scheduleStatus: 'scheduled'
      });
    }
  }, [selectedEmployee]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // toast.success("Employee schedule saved successfully!");

    const payload = {
      fullName: employeeData.fullName,
      doctorId: employeeData.doctorId,
      scheduleDate: employeeData.scheduleDate,
      scheduleTime: employeeData.scheduleTime,
      scheduleTimeEnd: employeeData.scheduleTimeEnd,
      scheduleType: employeeData.scheduleType,
      clinicId: employeeData.clinicId, // Assuming clinicId is a single value, not an array
      scheduleStatus: 'scheduled'
    };
    try {
      const response = await axios.post(
        `${server}/api/v2/schedule/create`,
        payload,
        { withCredentials: true }
      );
      console.log('Submitted Data:', response.data);
      toast.success("Schedule created Successfully!");
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || "Registration Failed");
    }
  }

  return (
    <div className="fixed top-[50%] right-[50%] translate-x-[50%] translate-y-[-50%] w-[80%] max-w-[600px] h-auto max-h-[70%] bg-white shadow-lg p-6 overflow-y-auto">
      <ToastContainer />
      <h2 className="text-xl font-bold mb-4">Create schedule for doctors.</h2>
      <p className="w-full border-b-4 mb-4 border-gray"></p>

      <form onSubmit={handleSubmit}>
        {/* Full Name (Non-Editable) */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
          <div>
            <label className="text-sm font-bold mb-1">Full Name</label>
            <p className="w-full border-b-2 border-gray-500 py-2">
              {employeeData.fullName}
            </p>
          </div>
        </div>

        {/* Editable Fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
          <div>
            <label>Schedule Date</label>
            <input
              type="date"
              name="scheduleDate"
              placeholder="Month | Day | Year"
              className="w-full border-b-2 border-gray-500 focus:border-black outline-none py-2"
              value={employeeData.scheduleDate}
              onChange={handleChange}
              min={getTodayDate()} // Block past dates
            />
          </div>
          <div>
            <label>Start Time</label>
            <input
              type="time"
              name="scheduleTime"
              placeholder="11:00"
              className="w-full border-b-2 mb-2 border-gray-500 focus:border-black outline-none py-2"
              value={employeeData.scheduleTime}
              onChange={handleChange}
            />
          </div>
          <div>
            <label>End Time</label>
            <input
              type="time"
              name="scheduleTimeEnd"
              placeholder="11:00"
              className="w-full border-b-2 mb-2 border-gray-500 focus:border-black outline-none py-2"
              value={employeeData.scheduleTimeEnd}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Schedule Type (Editable) */}
        <h3 className="text-lg mb-2">Schedule Type</h3>
        <div className="mb-4">
          <select
            value={employeeData.scheduleType}
            name="scheduleType"
            onChange={handleChange}
            className="w-[130px] border-b-2 border-gray-300 focus:border-black outline-none py-2 bg-transparent"
          >
            <option value="">Select Schedule Type</option>
            <option value="Daily">Daily</option>
            <option value="Weekly">Weekly</option>
            <option value="N/A">N/A</option>
          </select>
        </div>

        {/* Save/Cancel Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onClose}
            className="border border-gray-300 py-2 px-4 rounded hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-cyan-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditEmployeeForm;
