import React, { useState } from 'react';
import UploadFile from '../../../assets/Upload File.png';

const FileUpload = ({ closeFileUpload }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="max-w-sm mx-auto h-[280px]  p-2 mb-4 rounded-[45px] relative">
      <button
        type="button"
        onClick={closeFileUpload}
        className="absolute mt-[-35px] font-bold mb-4 text-[30px] right-[6px] text-gray-600 hover:text-gray-900 z-2 focus:outline-none"
      >
        &times;
      </button>
      <div >
      <p className="text-center h-[10px] text-gray-400 font-semibold mt-[6px] mb-8">UPLOAD FILE</p>

      </div>
      <div className='border-b-2'></div>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className="flex flex-col items-center justify-center border-gray-300 p-4 rounded-lg cursor-pointer hover:bg-gray-50"
      >
        <div className="mb-4">
          <img src={UploadFile} alt="upload file" />
        </div>
        <p className="text-center text-gray-400 font-semibold">DRAG AND DROP</p>
        <p className="text-center  text-sm text-gray-400">UPLOAD YOUR FILES HERE</p>
        <input
          type="file"
          onChange={handleFileChange}
          className="absolute  inset-0 mt-[50px] opacity-0 cursor-pointer"
        />
      </div>
      {file && (
        <div className="mt-4 text-center">
          <p className="text-gray-600">{file.name}</p>
        </div>
      )}<div className='mt-6'> </div>
      <button className=" w-[90px] ml-[130px] bg-cyan-500 text-white rounded-[15px] hover:bg-cyan-600">
        SAVE
      </button>
    </div>
  );
};

export default FileUpload;
