import React, { useState } from 'react';

const FilterOptions = () => {
  const [hideVisited, setHideVisited] = useState(true);
  const [showEmpty, setShowEmpty] = useState(false);

  return (
    <div className="flex w-[40%] items-center space-x-2 mb-4">
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={hideVisited}
          onChange={() => setHideVisited(!hideVisited)}
          className="form-checkbox h-4 w-4 text-blue-600"
        />
        <label className="ml-2 text-[#585E6A]">Hide visited</label>
      </div>
      <div className="flex items-center">
        <input
          type="checkbox"
          checked={showEmpty}
          onChange={() => setShowEmpty(!showEmpty)}
          className="form-checkbox h-4 w-4 text-blue-600"
        />
        <label className="ml-2 text-[#585E6A]">Show Empty</label>
      </div>
      <div className="relative">
        <button className=" border border-gray-300 rounded-md px-2 py-2 text-[#585E6A]">
          Display Columns
          <span className="ml-2">▼</span>
        </button>
   
      </div>
    </div>
  );
};

export default FilterOptions;
