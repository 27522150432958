import React from 'react';
import TopBar from '../../components/reception/reschedule/topBar';
import Sidebar from '../../components/reception/sideBar';
import AppointmentList from '../../components/reception/patient/rescheduleAppointment';

const RescheduleAppointment = () => {
  return (
    <div className="flex h-screen ">

      <div className="flex-1 p-6">
        <div className=''>
          <TopBar />
        </div>
        <AppointmentList />
        



      </div>
    </div>
  );
};

export default RescheduleAppointment;
